import React from 'react';

export default class Vision extends React.Component {
	// static propTypes = {
	// 	name: React.PropTypes.string,
	// };

	// constructor(props) {
	// 	super(props);
	// }

	render() {
		return (
			<div className="vision">
				<div className="vision--title">
					VISI
				</div>
				<div className="vision--desc">
					Menjadikan perusahaan yang berbasis edukasi pertanian dan peternakan yang terintegrasi yang memiliki keunggulan daya saing dan memberikan sumbangsih bagi Indonesia.
				</div>
			</div>
		);
	}
}
