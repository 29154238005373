import React from 'react';
import Logo from '../assets/img/logo.png'

class HeaderNavbar extends React.Component {
	// static propTypes = {
	// 	name: React.PropTypes.string,
	// };

	// constructor(props) {
	// 	super(props);
	// }

	render() {
		return (
			<div className="header-nav">
				<img className="header-nav--logo" src={Logo} alt="Sapimilkindo Logo"/>
				<hr/>
				<div className="container  header-nav--menu">
					<a href="#profile-component">PROFIL</a>
					<a href="#product-component">PRODUK</a>
					<a href="#info-component">KONTAK</a>
				</div>
			</div>
			);
	}
}

export default HeaderNavbar;