import React from 'react';
import Logo from '../assets/img/splash.png'

export default class SplashScreen extends React.Component {
	// static propTypes = {
	// 	name: React.PropTypes.string,
	// };

	// constructor(props) {
	// 	super(props);
	// }

	render() {
		return (
			<div className="splash">
				<div className="container">
					<img className='splash-logo' src={Logo} alt="Logo sapimilkindo" onClick={this.props.click}/>
					<h1>SAPIMILKINDO</h1>
				</div>
			</div>
		);
	}
}
