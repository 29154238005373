import React, { Component } from 'react';
// import logo from './logo.svg';
import './style/index.scss';
import SplashScreen from './components/SplashScreen';
import HeaderNavbar from './components/HeaderNavbar';
import Jumbotron from './components/Jumbotron';
import Profile from './components/Profile';
import Product from './components/Product';
import ImageSlider from './components/ImageSlider';
import Info from './components/Info';
import Footer from './components/Footer';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showSplash:true
    };
    this.clickSplash = this.clickSplash.bind(this);
  }

  clickSplash(){
    this.setState({
      showSplash: !this.state.showSplash
    })
  }

  render() {
    return (
      <div className="App">
      {
        this.state.showSplash ?
        <SplashScreen click={this.clickSplash}/>:
        <section id="main">
          <HeaderNavbar/>
          <Jumbotron/>
          <Profile/>
          <Product/>
          <ImageSlider/>
          <Info/>
          <Footer/>
        </section>
      }
      </div>
    );
  }
}

export default App;
