import React from 'react';
import Product1 from '../assets/img/product-1.png';
import Product2 from '../assets/img/product-2.png';
import Product3 from '../assets/img/product-3.png';
import Product4 from '../assets/img/product-4.png';

export default class Product extends React.Component {
	// static propTypes = {
	// 	name: React.PropTypes.string,
	// };

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="container product" id="product-component">
				<div className="product--type">
					<hr/>
					<div className="product--detail">
						<div className="title">SUSU SAPI</div>
						<div className="image">
							<img className="main-pic" src={Product1} alt="Susu Sapi"/>
						</div>
						<div className="align-center desc"><p>Susu sapi murni <span>(fresh milk)</span>
							<br/>Susu pasteurisasi <span>(ready-to-drink)</span>
							<br/>Yoghurt drink
							<br/>Yoghurt stick
							<br/>Pudding
							<br/>Mozzarella cheese
							</p>
						</div>
						<div className="detail">Kontak : <span><a href="mailto:sales@sapimilkindo.com" target="_blank" rel="noopener noreferrer">sales@sapimilkindo.com</a></span></div>
					</div>
				</div>
				<div className="product--type">
					<hr/>
					<div className="product--detail">
						<div className="title">PAKET EDUKASI</div>
						<div className="image">
							<img className="main-pic" src={Product2} alt="Paket Edukasi"/>
						</div>
						<div className="desc"><p>Paket wisata yang dipadukan dengan edukasi mengenai pertanian dan peternakan yang didampingi oleh pengajar yang kompeten pada bidangnya. Harga paket bervariasi mulai dari 
Rp 25.000,- per orang. 
							</p>
						</div>
						<div className="detail">Kontak : <span><a href="mailto:reservasi@sapimilkindo.com?subject=EDUKASI" target="_blank" rel="noopener noreferrer">reservasi@sapimilkindo.com</a></span> 
							<br/>(Subject : EDUKASI)
						</div>
					</div>
				</div>
				<div className="product--type">
					<hr/>
					<div className="product--detail">
						<div className="title">STUDI BANDING</div>
						<div className="image">
							<img className="main-pic" src={Product3} alt="Studi Banding"/>
						</div>
						<div className="desc"><p>Menjadi wadah bagi siswa-siswi, 
civitas akademika, pelaku usaha, 
dan instansi yang ingin berkunjung 
untuk studi banding, penelitian, 
pemagangan, dan berbagai aktivitas 
dan kerjasama lain yang terkait, khususnya dalam bidang pertanian, 
peternakan, pendidikan, dan pariwisata.
							</p>
						</div>
						<div className="detail">Kontak : <span><a href="mailto:reservasi@sapimilkindo.com?subject=STUDI BANDING" target="_blank" rel="noopener noreferrer">reservasi@sapimilkindo.com</a></span> 
							<br/>(Subject : STUDI BANDING)
						</div>
					</div>
				</div>
				<div className="product--type">
					<hr/>
					<div className="product--detail">
						<div className="title">WAHANA</div>
						<div className="image">
							<img className="main-pic" src={Product4} alt="Wahana"/>
						</div>
						<div className="desc"><p>CV. Milkindo Berka Abadi terbuka 
bagi wisatawan secara umum untuk 
berkunjung sebagai salah satu 
destinasi wisata di Kabupaten Malang. Saat ini tersedia area taman 
dan gazebo untuk berkumpul, spot selfie yang tersebar di berbagai tempat, pujasera, berbagai macam 
wahana permainan, dan pastinya 
aneka satwa seperti sapi, domba merino, kelinci, kuda, unggas, serta ikan.
							</p>
						</div>
						<div className="detail"></div>
					</div>
				</div>
				
			</div>
		);
	}
}
