import React from 'react';
import ArrowNav from '../assets/img/arrow.png';
import Photo1 from '../assets/img/photo-slider/slider-1.jpg';
import Photo2 from '../assets/img/photo-slider/slider-2.jpg';
import Photo3 from '../assets/img/photo-slider/slider-3.jpg';
import Photo4 from '../assets/img/photo-slider/slider-4.jpg';
import Photo5 from '../assets/img/photo-slider/slider-5.jpg';
import Photo6 from '../assets/img/photo-slider/slider-6.jpg';
import Photo7 from '../assets/img/photo-slider/slider-7.jpg';
import Photo8 from '../assets/img/photo-slider/slider-8.jpg';
import Photo9 from '../assets/img/photo-slider/slider-9.jpg';
import Photo10 from '../assets/img/photo-slider/slider-10.jpg';
import Photo11 from '../assets/img/photo-slider/slider-11.jpg';
import Photo12 from '../assets/img/photo-slider/slider-12.jpg';
import Photo13 from '../assets/img/photo-slider/slider-13.jpg';
import Photo14 from '../assets/img/photo-slider/slider-14.jpg';
import Photo15 from '../assets/img/photo-slider/slider-15.jpg';
import Photo16 from '../assets/img/photo-slider/slider-16.jpg';
import Photo17 from '../assets/img/photo-slider/slider-17.jpg';
import Photo18 from '../assets/img/photo-slider/slider-18.jpg';

const allArray = [Photo1, Photo2, Photo3, Photo4, Photo5, Photo6, Photo7, Photo8, Photo9, Photo10, Photo11, Photo12, Photo13, Photo14, Photo15, Photo16, Photo17, Photo18 ];

export default class ImageSlider extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			index:0,
			showArray: [],
			showPopup: false
		};
		this.changeIndex =  this.changeIndex.bind(this);
		this.arrayMove = this.arrayMove.bind(this);
		this.changePopup = this.changePopup.bind(this);
	}

	componentDidMount() {
		this.arrayMove("first");
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevState.index !== this.state.index){
			this.arrayMove();
		}
	}

	changeIndex(val){
		// console.log("index", val, this.state.index);
		const {index} = this.state;
		let newValue = (index == 0)&&(val == -1)? allArray.length-1:
										(index == allArray.length-1)&&(val == 1)? 0 :
										index + val;
		this.setState({
			index: newValue
		})
	}

	arrayMove(param){
		const { index } = this.state;
		// console.log("index for array move", index);
		const total = allArray.length;
		let tempArray = [];

		index >= (total-1)? tempArray = [allArray[index], allArray[0], allArray[1], allArray[2], allArray[3]]:
			index >= (total-2)? tempArray = [allArray[index], allArray[index+1], allArray[0], allArray[1], allArray[2]]:
			index >= (total-3)? tempArray = [allArray[index], allArray[index+1], allArray[index+2], allArray[0], allArray[1]]:
			index >= (total-4)? tempArray = [allArray[index], allArray[index+1], allArray[index+2], allArray[index+3], allArray[0]]:
			tempArray = [allArray[index], allArray[index+1], allArray[index+2], allArray[index+3], allArray[index+4]];

		this.setState({
			showArray: tempArray
		})
	}

	changePopup(){
		this.setState({
			showPopup: !this.state.showPopup
		})
	}



	render() {
		const { index, showArray, showPopup } = this.state;
		return (
			<div className="image-slider container">
				<div className="image-slider__image outer">
					<img src={showArray[0]} alt={'Sapimilkindo'+index}/>
				</div>
				<div className="image-slider__image inner">
					<img src={showArray[1]} alt={'Sapimilkindo'+(index+1)}/>
				</div>
				<div className="image-slider__image center">
					<img src={showArray[2]} alt={'Sapimilkindo'+(index+2)} onClick={this.changePopup}/>
					<div className="image-slider__navigation">
						<img src={ArrowNav} className="rotated-180" alt="left-navigation" onClick={()=>this.changeIndex(-1)}/>
						<div className="center-navigation" onClick={this.changePopup}></div>
						<img src={ArrowNav} alt="left-navigation" onClick={()=>this.changeIndex(1)}/>
					</div>
					{
						showPopup &&
						<div className="popup-image" onClick={this.changePopup}>
							<div className="img-container">
								<img src={showArray[2]} alt={'Sapimilkindo'+(index+2)}/>
							</div>
						</div>
					}
				</div>
				<div className="image-slider__image inner">
					<img src={showArray[3]} alt={'Sapimilkindo'+(index+3)}/>
				</div>
				<div className="image-slider__image outer">
					<img src={showArray[4]} alt={'Sapimilkindo'+(index+4)}/>
				</div>
			</div>
		);
	}
}
