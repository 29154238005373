import React from 'react';
import Openday from '../assets/img/openday.jpg';
import Maps from '../assets/img/gmap-milkindo.png';
import IconPhone from '../assets/img/phone.png';
import IconFacebook from '../assets/img/icon-facebook.png';
import IconWhatsapp from '../assets/img/icon-whatsapp.png';
import IconInstagram from '../assets/img/icon-instagram.png';
import IconTwitter from '../assets/img/icon-twitter.png';
import IconYoutube from '../assets/img/icon-youtube.png';
import IconMail from '../assets/img/icon-mail.png';

export default class Info extends React.Component {
	// static propTypes = {
	// 	name: React.PropTypes.string,
	// };

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="more-info" id="info-component">
				<div className="container">
					<img className="main-pic" src={Openday} alt="Hari Buka"/>
					<div className="info-contact">
						<div className="left-content">
							<a href="https://www.google.com/maps/place/Milkindo+Green+Farm+Kepanjen/@-8.1708023,112.5726544,15z/data=!4m5!3m4!1s0x0:0x8cb1272e7b6fdff4!8m2!3d-8.1678517!4d112.5713967" target="_blank" rel="noopener noreferrer">
								<img className="main-pic" src={Maps} alt="Hari Buka"/>
							</a>
						</div>
						<div className="right-content">
							<p>Jalan Kolonel Kusno 77, Desa Tegalsari, Kepanjen, Malang, Jawa Timur, 65163.</p>
							<div className="phone-content">
								<div className="phone-icon">
									<img src={IconPhone} alt="Telephone"/>
								</div>
								<div className="phone-number">
									<p>0341 – 390 6000 (HOTLINE)</p>
									<p>082 – 121 121 600 (KRITIK & SARAN)</p>
								</div>
							</div>
							<div className="another-contact">
								<a href="https://www.facebook.com/sapimilkindo/" target="_blank" rel="noopener noreferrer"><img src={IconFacebook} alt="Facebook"/></a>
								<a href="https://wa.me/6285104702777" target="_blank" rel="noopener noreferrer"><img src={IconWhatsapp} alt="Whatsapp"/></a>
								<a href="https://www.instagram.com/sapimilkindo/" target="_blank" rel="noopener noreferrer"><img src={IconInstagram} alt="Instagram"/></a>
								<a href="https://twitter.com/sapimilkindo" target="_blank" rel="noopener noreferrer"><img src={IconTwitter} alt="Twitter"/></a>
								<a href="https://www.youtube.com/channel/UC8NHahwezyBUMO9h9BrLfDw" target="_blank" rel="noopener noreferrer"><img src={IconYoutube} alt="Youtube"/></a>
								<a href="mailto:info@sapimilkindo.com" target="_blank" rel="noopener noreferrer"><img src={IconMail} alt="Mail"/></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
