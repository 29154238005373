import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group'
import ProfileTitle from '../assets/img/welcome.jpg';
import Vision from './Vision';

class Profile extends React.Component {
	// static propTypes = {
	// 	name: React.PropTypes.string,
	// };

	constructor(props) {
		super(props);
		this.state = {
			showDetail:false
		};
		this.clickProfile = this.clickProfile.bind(this);
	}

	clickProfile(){
		this.setState({
			showDetail: !this.state.showDetail
		})
	}

	render() {
		const {showDetail} = this.state;
		return (
			<article className="container profile" id="profile-component">
				<div className="profile--title">
					<img className="" src={ProfileTitle} alt="Welcome to Sapi Milkindo"/>
				</div>
				<div className="profile--content">
					<div className="sub-content">
						<div className="content-title" onClick={this.clickProfile}>1984</div>
						<CSSTransitionGroup
            transitionName='show-hide-transition'
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}>
						{
							showDetail &&
							<div className="content-desc">
								Dimulai dari sebuah peternakan sapi rumahan  pada tahun 1984 di Desa Tegalsari, Kepanjen, Malang, Jawa Timur dengan populasi awal sejumlah 8 ekor. Tidak langsung menambah dalam jumlah yang banyak namun pemilik memilih mengembangkan peternakannya secara perlahan.
							.</div>
						}
						</CSSTransitionGroup>
					</div>
					<hr/>
					<div className="sub-content">
						<div className="content-title" onClick={this.clickProfile}>2000</div>
						<CSSTransitionGroup
            transitionName='show-hide-transition'
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}>
						{
							showDetail &&
							<div className="content-desc">
								Pada tahun 2000, pemilik mengubah menjadi sebuah peternakan semi modern melalui pembangunan kandang sapi yang sesuai standart nasional serta penggunaan mesin pemerahan dengan tujuan untuk mencapai standar kebersihan kandang dan kesehatan ternak. 
							</div>
						}
						</CSSTransitionGroup>
					</div>
					<hr/>
					<div className="sub-content">
						<div className="content-title" onClick={this.clickProfile}>2011</div>
						<CSSTransitionGroup
            transitionName='show-hide-transition'
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}>
						{
							showDetail &&
							<div className="content-desc">
								Hingga pada tahun 2011, mulai memiliki penataan manajemen yang baik dengan lahirnya CV. Milkindo Berka Abadi, 
							</div>
						}
						</CSSTransitionGroup>
					</div>
					<hr/>
					<div className="sub-content">
						<div className="content-title" onClick={this.clickProfile}>SEKARANG</div>
						<CSSTransitionGroup
            transitionName='show-hide-transition'
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}>
						{
							showDetail &&
							<div className="content-desc">
								Hingga tahun ini telah banyak perubahan positif yang telah dicapai oleh CV. Milkindo Berka Abadi meliputi penataan manajemen peternakan dan administratif, pemanfaatan limbah ternak, serta hasil produk berupa susu sapi segar, susu pasteurisasi, yoghurt, keju Mozzarella, dan pudding dengan  berbagai varian rasa. CV. Milkindo Berka Abadi telah mengembangkan lahan yang ada sebagai pusat wisata edukasi yang terbuka bagi masyarakat luas untuk bersama-sama belajar dan bersinergi khususnya dalam bidang pertanian, peternakan, pendidikan, dan pariwisata.
							</div>
						}
						</CSSTransitionGroup>
					</div>
				</div>
				<CSSTransitionGroup
            transitionName='show-hide-transition'
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}>
				{
					showDetail &&
					<Vision />
				}
				</CSSTransitionGroup>
			</article>
		);
	}
}

export default Profile;