import React from 'react';

export default class Footer extends React.Component {
	// static propTypes = {
	// 	name: React.PropTypes.string,
	// };

	// constructor(props) {
	// 	super(props);
	// }

	render() {
		return (
			<div className="footer">
				<p>Copyright 2019 Sapi Milkindo. All rights reserved.</p>
				<p>by GRIDLABS</p>
			</div>
		);
	}
}
